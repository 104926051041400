import Vue from 'vue'
import Vuex from 'vuex'
import {getDate} from "vuetify/lib/components/VCalendar/util/timestamp";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api_path: "",
        //  api_path: '/php/api.php',
        current_doc: '',
        defs: {},
        search_open: false,
        db_titles: [],
        db_docs: [
            {'doc_id': 7, text: 'doc 7', type: 'text', ord: 1, id: 1},
            {'doc_id': 8, text: 'doc 8', type: 'text', ord: 1, id: 2},
            {'doc_id': 9, text: 'doc 9', type: 'text', ord: 1, id: 3},
            {'doc_id': 6, text: 'doc 6', type: 'text', ord: 1, id: 4},

        ],
        catalog: [],
        user: null

    },
    mutations: {
        set_api(state, a) {
          state.api_path = a;
        },
        do_search_open(state, a) {
            state.search_open = a;
        },
        get_titles(state, a) {
            state.db_titles = a;

        },
        add_new_record(state, a) {
            state.db_titles.push(a);
            let idx = state.db_titles.findIndex((item) => item.id === a.parent_id);
            state.db_titles[idx].open = true;
            this.dispatch('set_new_title', state.db_titles);
        },
        set_user(state, a) {
            state.user = a;
        },
        set_current_doc(state, title) {
            state.current_doc = title;
        }

    },
    actions: {
        sec_key(context, login) {
            return login + "duskpc";
        },
        set_new_title(context, obj) {
            axios(
                {
                    method: 'post',
                    url: this.state.api_path,
                    data: {
                        f: "set_new_title",
                        obj: obj,
                        user: this.state.user
                    }
                })
                .then(function (response) {
                    if (response.data == 'ok')
                        context.dispatch('get_titles', []);
                    else alert('fail');
                })

        },

        get_titles(context) {
            function get_max_id(t) {
                let json = t;
                json.sort(function (a, b) {
                    return a.id - b.id;
                })
                return json;
            }
            return new Promise((resolve, reject) => {
                axios.get(this.state.api_path + "?f=get_titles&user_id=" + this.state.user)
                    .then(function (response) {
                        context.commit("get_titles", response.data);
                        setTimeout(() => {
                            resolve("ok");
                        }, 100);

                    })
            });
        },
        delete_item(context, id) {
            return new Promise((resolve, reject) => {
                axios.get(this.state.api_path + "?f=delete_item&id=" + id + "&user=" + this.state.user)
                    .then(function (response) {
                        if (response.data == 'ok') {
                           // context.dispatch("get_titles");
                            resolve(response.data);
                        } else {
                            alert(response.data);
                        }
                    })
            })
        },
    },
})
