<template>
  <div class="docs_wrapper" style="">
    <div v-if="!no_doc" class="doc-wrapper flex100col">
      <div class="up-pan flex100row">
        <div class="aw">
          <v-btn small class="info">{{ $store.state.current_doc }}</v-btn>
          <!--  <v-btn small disabled class="ml-2">Форматированный текст</v-btn>-->
          <div>
            <textarea v-model="stext1"></textarea>
          </div>
        </div>
        <div class="aw">
          <v-btn small class="info">{{ $store.state.current_doc }}</v-btn>
          <!--<v-btn small disabled class="ml-2">Форматированный текст</v-btn>-->
          <div>
            <textarea v-model="stext2"></textarea>
          </div>
        </div>
      </div>
      <div class="btn-wrap" style="height: 42px;">
        <v-btn :disabled="!save_active" style="width: 100%" class="primary" @click="save_doc()">Сохранить</v-btn>
      </div>
    </div>
    <div v-else>
      Ошибка 404! Документ не существует!
    </div>
    <v-snackbar
        v-model="snackbar"
        timeout="1000"
        right
        rounded="pill"
    >
      Сохранено
      <template v-slot:action="{ attrs }">
        <v-btn
            v-bind="attrs"
            color="blue"
            text
            @click="snackbar = false"
        >
          Понятно
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
let md5 = require('locutus/php/strings/md5');
export default {
  name: "doc",
  data() {
    return {
      key: '',
      snackbar: false,
      stext1: '',
      stext2: '',
      no_doc: false,
      orig: [],
      changed: [0, 0],
      save_active: false,
    };
  },
  watch: {
    '$route.params.doc'(a) {
      this.key = getCookie('hash') + " " + md5(getCookie('hashp')) + " " + this.$route.params.doc;
      this.get_doc();
    },
    stext1(a) {
      if (a.trim() !== this.orig[0].text.trim()) {
        this.changed[0] = 1;
      } else {
        this.changed[0] = 0;
      }
      if (this.changed[0] === 1 || this.changed[1] === 1) {
        this.save_active = true;
      } else {
        this.save_active = false;
      }
    },
    stext2(a) {
      if (a !== this.orig[1].text) {
        this.changed[1] = 1;
      } else {
        this.changed[1] = 0;
      }
      if (this.changed[0] === 1 || this.changed[1] === 1) {
        this.save_active = true;
      } else {
        this.save_active = false;
      }
    },
  },
  mounted() {
    let vm2 = this;
    vm2.key = getCookie('hash') + " " + md5(getCookie('hashp')) + " " + this.$route.params.doc;
    setTimeout(() => {
      vm2.get_doc();
    }, 50)
  },

  methods: {
    get_doc() {
      let vm2 = this;
      this.orig = [];

      axios.get(vm2.$store.state.api_path + '?f=get_doc&user_id=' + vm2.$store.state.user + '&doc_id=' + this.$route.params.doc)
          .then(function (response) {
            if (response.data != '0') {
              if (response.data == '') {
                vm2.stext1 = "";
                vm2.stext2 = "";
                vm2.orig = Object.assign([], [
                  {text: vm2.stext1},
                  {text: vm2.stext2},
                ]);
              } else {
                let s = CryptoJS.AES.decrypt(response.data, vm2.key);
                setTimeout(() => {
                  let obj = JSON.parse(s.toString(CryptoJS.enc.Utf8));
                  vm2.stext1 = obj[0].text;
                  vm2.stext2 = obj[1].text;
                  vm2.orig = Object.assign([], [
                    {text: vm2.stext1},
                    {text: vm2.stext2},
                  ]);
                }, 40)
              }
            } else {
              vm2.no_doc = true;
            }
          })
    },
    save_doc_prepare() {

    },
    save_doc() {
      let vm2 = this;
      let s = JSON.stringify([{"text": vm2.stext1}, {"text": vm2.stext2}]);
      let enc = CryptoJS.AES.encrypt(s, vm2.key).toString();
      axios(
          {
            method: 'post',
            url: vm2.$store.state.api_path,
            data: {
              user_id: vm2.$store.state.user,
              doc_id: vm2.$route.params.doc,
              db_datas: enc,
              f: "save_doc"
            }
          }
      )
          .then(function (response) {
            if (response.data == 'ok') {
              vm2.snackbar = true;
              vm2.changed = [0, 0];
              vm2.save_active = false;
              vm2.orig = Object.assign([], [
                {text: vm2.stext1},
                {text: vm2.stext2},
              ]);
            } else alert(response.data);
          })
    }
  }
};
</script>
<style scoped>
textarea {
  line-height: 155%;
  font-size: 105%;
  color: #353535;
}
</style>