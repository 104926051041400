import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/document/:level1/:level2/:doc',
      name: 'document3',
      component: Home
    },
    {
      path: '/document/:level1/:doc',
      name: 'document2',
      component: Home
    },

  ]
})