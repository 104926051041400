<template>
  <div>
    <div v-if="$store.state.user">
      <maind/>
    </div>
    <div v-else>
      <p>
        <b>"BOOKSHELF SECURITY"</b> - это ваша записная книжка с высокой степенью защиты.
      </p>
      <p>В ней Вы можете хранить тексты, личные данные и пароли, которые надежно шифруются и не могут быть прочитаны
        никем, кроме владельцев Вашего логина и пароля</p>
      <p>
      <div style="margin-top: 100px">
        <img style="float: right" src="/img/locked.png" alt="">
      </div>
      </p>
    </div>
    <Search_dialog :change_dialog="change_dialog" :dialog_open="dialog_open"></Search_dialog>
  </div>
</template>

<script>
import maind from '../components/main'
import Search_dialog from "@/components/search_dialog";

export default {
  name: 'Home',

  components: {
    Search_dialog,
    maind
  },
  data() {
    return {
      dialog_open: false,
    }
  },
  mounted() {
   // document.addEventListener("keypress", (e) => {
   // })
  },
  watch: {
    '$store.state.search_open'(a) {
      if (a) this.change_dialog(a);
    }
  },
  methods: {
    change_dialog(d) {
      this.dialog_open = d;
      if (!d) this.$store.commit('do_search_open', d);
    },
    get_titles() {
      this.$store.dispatch("get_titles");
    }
  }
}
</script>
<style scoped lang="less">
p {
  font-size: 130%;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
}
</style>