<template>
  <v-app>
    <div class="flex100col" style="flex: 0; width: 100%; height: 100vh">
      <div class="flex100row flex-sb ats-c flex-wrap up-wrapper"
           style="padding: 8px 12px; color: #dedede; background-color: #1d53b1;">
        <div> BOOKSHELF SECURITY</div>
        <div>
          <div v-if="$store.state.user">
            Вход произведен: User_id={{ $store.state.user }}
            <v-btn small class="mx-2" @click="out(1); user.login=''; user.pass = ''">Выход</v-btn>
          </div>

        </div>
        <div title="Поиск в записях" style="cursor: pointer" @click="$store.commit('do_search_open', true)">
          <v-icon color="#fff">mdi-magnify</v-icon>
          - поиск
        </div>
        <div class="hm">
          <v-btn
              text
              color="#CCC"
          >
        <span class="mr-2">Version <b style="color: #0bff7b">{{ version }}</b>
        </span>

          </v-btn>
        </div>
      </div>
      <div v-if="!$store.state.user" class="up_form flex100row ats-c flex-wrap" style="justify-content: center">
        <div class="overlay">
          <div>
            <div class="up-title">Вход в BookShelf</div>
            <form>
              <div>
                <v-text-field outlined background-color="#eee"  label="E-mail" @keydown.enter="enter()" v-model.trim="user.login" placeholder="Login"></v-text-field>

                <v-text-field :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" outlined background-color="#eee"  label="Пароль" id="pwd" @keydown.enter="enter()" v-model.trim="user.pass"
                              :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" placeholder="Password"></v-text-field>
              </div>
              <div style="text-align: center">

                <v-btn @click="registration()" small class="normal">Регистрация</v-btn>
                <v-btn @click="enter()" small class="success ml-3">Вход</v-btn> &nbsp;
              </div>
            </form>
          </div>
        </div>


      </div>
    </div>

    <div style="width: 100vw; overflow-x: hidden; flex: 1">
      <router-view/>
    </div>

  </v-app>
</template>

<script>
let md5 = require('locutus/php/strings/md5')
export default {
  name: 'App',

  data: () => ({
    me: '',
    user: {
      login: '',
      pass: '',
      search_open: false,
      cookie_age: 1800
    },
    showPass: false,
    version: "1.2"
  }),
  mounted() {
    let vm2 = this;
    this.$store.commit("set_api", api);
    if (getCookie('userId')) {

      this.$store.commit('set_user', getCookie('userId'));
      setTimeout(() => {
        vm2.$store.dispatch('get_titles');
      }, 200)

    } else {
      this.get_login_from_ip();
    }
    document.title = "BookShelf v." + this.version;
  },
  methods: {
    get_login_from_ip() {
      let vm2 = this;
      axios.get(vm2.$store.state.api_path + "?f=get_login_from_ip")
          .then(function (response) {
            vm2.user.login = response.data;
          })
    },
    out(n = 0) {
      deleteCookie('userId');
      deleteCookie('login');
      deleteCookie('hash');
      deleteCookie('hashp');
      this.$store.commit('set_user', null);
      if (n === 1) location.assign("/");
    },
    enter() {
      if (this.user.login && this.user.pass) {
        let vm2 = this;
        let key = this.user.login + ' ' + this.user.pass;
        this.out();
        axios(
            {
              method: 'post',
              url: vm2.$store.state.api_path,
              data: {
                f: 'user_confirm',
                mode: 'user',
                login: md5(vm2.user.login)
              }
            }
        )
            .then(function (response) {
              if (response.data == '0') {
                alert("Ошибка авторизации!");
              } else {
                let decoded = response.data.enc;
                //   let decoded = decodeURIComponent(response.data.pass);
                let s = CryptoJS.AES.decrypt(decoded, key);
                let obj = s.toString(CryptoJS.enc.Utf8);
                if (obj == vm2.user.pass) {
                  setCookie('userId', response.data.user_id, {
                    'max-age': vm2.cookie_age
                  });
                  setCookie('login', vm2.user.login, {
                    'max-age': vm2.cookie_age
                  });
                  setCookie('hashp', md5(vm2.user.pass + "1"), {
                    'max-age': vm2.cookie_age
                  });
                  setCookie('hash', response.data.enc, {
                    'max-age': vm2.cookie_age
                  });
                  vm2.$store.commit('set_user', response.data.user_id);
                  setTimeout(() => {
                    location.reload()
                  }, 100)
                }

              }
            })
      } else {
        alert('Укажите E-mail и пароль для входа!');
      }
    },
    registration() {
      if (this.user.login && this.user.pass) {
        let vm2 = this;
        let key = '';
        this.$store.dispatch('sec_key', this.user.login)
            .then(function (result) {
              key = result;
            });

        if (confirm('Региструясь на сайте, Вы должны помнить, что введенный Вами пароль не восстанавливается, и при его утере, все данные будут безвозвратно утеряны!!! ')) {
          setTimeout(() => {
            let enc = CryptoJS.AES.encrypt(this.user.pass, this.user.login + ' ' + this.user.pass).toString();
            //   let coded = encodeURIComponent(enc);
            let decoded = decodeURIComponent(enc);
            let s = CryptoJS.AES.decrypt(decoded, key);
            let obj = s.toString(CryptoJS.enc.Utf8);
            axios(
                {
                  method: 'post',
                  url: vm2.$store.state.api_path,
                  data: {
                    f: 'user_confirm',
                    mode: 'new_user',
                    code: enc,
                    login: vm2.user.login
                  }
                }
            )
                .then(function (response) {
                  if (response.data === "ok") {
                    alert("Регистрация успешна!");
                  } else
                    alert(response.data);
                })

          }, 300);

        } else {
          alert('Укажите E-mail и пароль для регистрации!');
        }

      }

    }
  }
}
</script>
<style scoped lang="less">
.up-wrapper {

  div {
    transition: all 0.3s ease;
  }

  .hm {
    display: inline-block;
  }

  @media (max-width: 926px) {
    & > div {
      text-align: center;
      padding: 8px 5px;
      width: 100%;
    }

    .hm {
      display: none;
    }
  }
  @media (max-width: 720px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
}

.up_form {
  & > div {
    padding: 5px 3px;
  }

  input {
    background: #eee;
    margin: 0 5px;
    padding: 2px 4px;
    font-size: 90%;
    width: 140px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  & > div {
    width: 320px;
    background: #eeeeee;
    padding: 8px;
    border: 2px solid #555;
    border-radius: 6px;
    .up-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #0016c2;
      padding-bottom: 6px;
      border-bottom: #aaa 1px solid;
      margin-bottom: 16px;
    }
  }
}
</style>
